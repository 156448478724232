.heading {
  font-family: "Montagu Slab", serif;
}

.no-link-style {
  text-decoration: none !important;
  color: inherit;
}

.page_container {
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
  justify-content: center; 
  align-items: center; 
  margin: 0 auto;
}

.content {
  flex-grow: 1;
}
